import React from 'react';
import { Link, graphql } from 'gatsby';

import SEO from '../components/SEO';
import PageLayout from '../components/PageLayout';

interface Post {
  excerpt: string;
  timeToRead: number;
  frontmatter: {
    date: string;
    title?: string | null;
    description?: string | null;
  };
  fields: {
    slug: string;
  };
}

interface PostEdge {
  node: Post;
}

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string;
        description: string;
      };
    };
    allMarkdownRemark: {
      edges: PostEdge[];
    };
  };
}

const BlogIndex = ({ data }: Props) => {
  const posts = data.allMarkdownRemark.edges;

  return (
    <PageLayout homepageDescription={data.site.siteMetadata.description}>
      <SEO />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        return (
          <article key={node.fields.slug} style={{ paddingBottom: '0.1rem' }}>
            <header>
              <h3
                style={{
                  marginBottom: '0.4rem',
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{`${node.frontmatter.date} • ${node.timeToRead} min read`}</small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        );
      })}
    </PageLayout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
